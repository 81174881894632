
import { Performers_performers_PerformerResults_results } from "@/api/performer/__generated__/Performers";
import PerformerCard from "@/shared/components/Performers/PerformerCard.vue";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import isEmpty from "lodash/isEmpty";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";

/**
 * A generic grid to display Video Ideas as cards in a grid.
 */
export default defineComponent({
  props: {
    performersList: {
      type: Array as PropType<Performers_performers_PerformerResults_results[]>,
      required: true,
    },
    selectedPerformerIds: {
      type: Array as PropType<(string | number)[]>,
      default: () => [],
    },
    hasNextPage: Boolean,
    loading: Boolean,
    loadMoreLoading: Boolean,
    disableItemClick: Boolean,
  },
  setup() {
    const { t } = useI18n();

    return { t, isEmpty, DEFAULT_PAGE_SIZE };
  },
  emits: ["on-item-click", "on-item-delete", "on-load-more"],
  components: {
    PerformerCard,
  },
});
