import gql from "graphql-tag";

export const purposesGql = gql`
  query Purposes {
    purposes {
      ... on PurposeResults {
        results {
          id
          name
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
