
import { Performers_performers_PerformerResults_results } from "@/api/performer/__generated__/Performers";
import IconLabel from "@/shared/components/IconLabel.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { fallbackSrc } from "@/shared/utils/constants";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { PerformerStat } from "@/shared/utils/performerProfileHelper";
import PerformerBadgeList from "@/shared/components/Performers/PerformerBadgeList.vue";
import PerformerRating from "@/shared/components/Performers/PerformerRating.vue";
import PerformerIndustries from "@/shared/components/Performers/PerformerIndustries.vue";
import { getIndustryOptionData } from "@/shared/utils/kycOptions";

export default defineComponent({
  emits: ["on-click", "on-delete"],
  props: {
    performer: {
      type: Object as PropType<Performers_performers_PerformerResults_results>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selected: Boolean,
    /** Disabled click and hover events */
    static: Boolean,
    miniCard: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const performerStats = computed(
      () =>
        [
          // Number of Videos
          {
            value: props.performer.performerProfile?.videoCount,
            iconName: "playButton",
          },
          // Delivery Time
          {
            iconName: "clock",
            value: props.performer.performerProfile?.deliveryTime,
          },
        ].filter((stat) => !!stat.value) as PerformerStat[]
    );

    return {
      t,
      fallbackSrc,
      performerStats,
      getIndustryOptionData,
    };
  },
  components: {
    AppIcon,
    IconLabel,
    PerformerBadgeList,
    PerformerRating,
    PerformerIndustries,
  },
});
