import { audiencesGql } from "@/api/filters/audiences";
import { industriesGql } from "@/api/filters/industries";
import { purposesGql } from "@/api/filters/purposes";
import { typesGql } from "@/api/filters/types";
import {
  Audiences,
  Audiences_audiences_AudienceResults,
} from "@/api/filters/__generated__/Audiences";
import {
  Industries,
  Industries_industries_IndustryResults,
} from "@/api/filters/__generated__/Industries";
import {
  Purposes,
  Purposes_purposes_PurposeResults,
} from "@/api/filters/__generated__/Purposes";
import {
  Types,
  Types_types_TypeResults,
  Types_types_TypeResults_results,
} from "@/api/filters/__generated__/Types";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { computed, watchEffect } from "vue";
import { orderBy } from "lodash";
import { saveVideosFilterToStorage } from "@/gtag";
import { ButtonSelectCarouselOptionData } from "@/shared/components/ButtonSelectCarousel/ButtonSelectCarouselOption.vue";
import {
  KycSelectOptionWithImage,
  getIndustryOptionData,
} from "@/shared/utils/kycOptions";
import { DropdownOptionObject } from "@/shared/components/Forms/InputDropdown.vue";
import { i18nTranslate } from "@/plugins/i18n";
import { orderByPriority } from "@/shared/utils/arrayUtils";

/**
 * A mapper function that returns the ID of filter option in `{number}` (default is `string`).
 * @param option `<Industry | Audience | Purpose | Type>` The filter option returned by graphql.
 */
const mapIdFunction = (option) => Number(option.id);

/** List of Video Types to prioritize, in order. */
const TYPES_PRIORITY = [
  "UGC",
  "Tiktok/IG Trends",
  "Video Ad",
  "Product Highlight",
  "How-To Video",
  "Funny Skit",
];

export const useFilterOptions = () => {
  // gql queries
  const { loading: audiencesLoading, result: audiencesResult } =
    useQuery<Audiences>(audiencesGql);

  const { loading: industriesLoading, result: industriesResult } =
    useQuery<Industries>(industriesGql);

  const { loading: purposesLoading, result: purposesResult } =
    useQuery<Purposes>(purposesGql);

  const { loading: typesLoading, result: typesResult } =
    useQuery<Types>(typesGql);

  // Parsed Responses
  const audiences = computed(() => {
    return parseGqlResponse<Audiences_audiences_AudienceResults>(
      "AudienceResults",
      audiencesResult.value
    ).data?.results;
  });

  const industries = computed(() => {
    return parseGqlResponse<Industries_industries_IndustryResults>(
      "IndustryResults",
      industriesResult.value
    ).data?.results;
  });

  const purposes = computed(() => {
    return parseGqlResponse<Purposes_purposes_PurposeResults>(
      "PurposeResults",
      purposesResult.value
    ).data?.results;
  });

  const types = computed(() => {
    return parseGqlResponse<Types_types_TypeResults>(
      "TypeResults",
      typesResult.value
    ).data?.results;
  });

  /**
   * Save to storage for gtag when industries or types changes
   */
  watchEffect(() => {
    saveVideosFilterToStorage({
      industry: industries.value,
      type: types.value,
    });
  });

  const industryCheckboxImageOptions = computed<KycSelectOptionWithImage[]>(
    () => {
      return (
        industries.value?.map((industry) => {
          const data = getIndustryOptionData(industry?.name ?? "");

          return {
            bgIconName: data.bgIconName,
            label: data.label,
            value: industry!.id,
          };
        }) ?? []
      );
    }
  );

  const industryDropdownOptions = computed<DropdownOptionObject[]>(() => {
    return [
      { label: i18nTranslate("All"), value: null },
      ...(industries.value?.map((industry) => {
        const data = getIndustryOptionData(industry?.name ?? "");

        return {
          value: industry!.id,
          label: data.label,
          appIconName: data.dropdownIcon,
        } as DropdownOptionObject;
      }) ?? []),
    ];
  });

  const orderedTypes = computed<Types_types_TypeResults_results[]>(() =>
    orderBy(types.value, [mapIdFunction], ["asc"])
  );

  const orderedTypesByPriority = computed(() =>
    orderByPriority(orderedTypes.value, TYPES_PRIORITY, "name")
  );

  const typesWithIconOptions = computed<ButtonSelectCarouselOptionData[]>(
    () => {
      return (
        orderedTypesByPriority.value?.map((item) => ({
          ...item,
          title: item?.name ?? "",
          value: item?.id ?? "",
          iconName: item?.name ?? "",
        })) ?? []
      );
    }
  );

  return {
    filterOptions: {
      audiences: computed(() =>
        orderBy(audiences.value, [mapIdFunction], ["asc"])
      ),
      industries: computed(() =>
        orderBy(industries.value, [mapIdFunction], ["asc"])
      ),
      purposes: computed(() =>
        orderBy(purposes.value, [mapIdFunction], ["asc"])
      ),
      types: orderedTypesByPriority,
    },
    filterOptionsLoading:
      audiencesLoading || industriesLoading || purposesLoading || typesLoading,
    typesWithIconOptions,
    industryCheckboxImageOptions,
    industryDropdownOptions,
  };
};
