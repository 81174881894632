import gql from "graphql-tag";

export const industriesGql = gql`
  query Industries {
    industries {
      ... on IndustryResults {
        results {
          id
          name
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
