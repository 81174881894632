import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    value: _ctx.$attrs.value,
    rules: _ctx.$attrs.rules,
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help,
    validateFirst: _ctx.validateFirst,
    validateTrigger: _ctx.$attrs.validateTrigger
  }, {
    labelEnd: _withCtx(() => [
      _renderSlot(_ctx.$slots, "labelEnd")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_auto_complete, _mergeProps({ autocomplete: "disabled" }, _ctx.$attrs, {
        placeholder: "",
        "on-focus": _ctx.focusHandler,
        "on-blur": _ctx.blurHandler,
        ref: "inputRef",
        autocorrect: "disabled",
        autocapitalize: "disabled",
        spellcheck: "false"
      }), {
        prefix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "prefix")
        ]),
        suffix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "suffix")
        ]),
        addonAfter: _withCtx(() => [
          _renderSlot(_ctx.$slots, "addonAfter")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_input_search, {
            placeholder: _ctx.$attrs.placeholder,
            "enter-button": _ctx.$attrs['enter-button'],
            onSearch: _ctx.$attrs['on-enter-button'],
            loading: _ctx.$attrs.loading
          }, null, 8, ["placeholder", "enter-button", "onSearch", "loading"])
        ]),
        _: 3
      }, 16, ["on-focus", "on-blur"])
    ]),
    _: 3
  }, 8, ["label", "name", "value", "rules", "extra", "help", "validateFirst", "validateTrigger"]))
}