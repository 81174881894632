import gql from "graphql-tag";

export const audiencesGql = gql`
  query Audiences {
    audiences {
      ... on AudienceResults {
        results {
          id
          name
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
