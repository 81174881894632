import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "performer-grid" }
const _hoisted_3 = {
  key: 0,
  class: "text-center mt-16"
}
const _hoisted_4 = { class: "performer-grid__empty-message flex my-60" }
const _hoisted_5 = { class: "m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PerformerCard = _resolveComponent("PerformerCard")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!

  return (_ctx.loading || _ctx.loadMoreLoading || !_ctx.isEmpty(_ctx.performersList))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.loading && !_ctx.loadMoreLoading)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.DEFAULT_PAGE_SIZE, (num) => {
                return (_openBlock(), _createBlock(_component_PerformerCard, {
                  key: num,
                  loading: _ctx.loading
                }, null, 8, ["loading"]))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.performersList, (performer) => {
                return (_openBlock(), _createBlock(_component_PerformerCard, {
                  performer: performer,
                  key: performer.id,
                  selected: _ctx.selectedPerformerIds.includes(performer.id),
                  onOnClick: ($event: any) => (_ctx.$emit('on-item-click', performer.id)),
                  onOnDelete: ($event: any) => (_ctx.$emit('on-item-delete', performer.id)),
                  static: _ctx.disableItemClick
                }, null, 8, ["performer", "selected", "onOnClick", "onOnDelete", "static"]))
              }), 128))
        ]),
        (_ctx.hasNextPage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_a_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-load-more'))),
                loading: _ctx.loadMoreLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Load more")), 1)
                ]),
                _: 1
              }, 8, ["loading"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _renderSlot(_ctx.$slots, "empty", { key: 1 }, () => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_typography_title, { level: 2 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("No items in list.")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ])
}