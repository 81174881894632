import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute top-4 right-4"
}
const _hoisted_3 = { class: "performer-card__container flex flex-col gap-y-2" }
const _hoisted_4 = {
  key: 1,
  class: "performer-card__stats"
}
const _hoisted_5 = {
  key: 0,
  class: "separator"
}
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_PerformerRating = _resolveComponent("PerformerRating")!
  const _component_PerformerBadgeList = _resolveComponent("PerformerBadgeList")!
  const _component_PerformerIndustries = _resolveComponent("PerformerIndustries")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        active: "",
        width: "100%"
      }))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["performer-card relative", [
      _ctx.performer.performerProfile?.isLocked && 'performer-card--locked',
      _ctx.selected && 'performer-card--selected',
      _ctx.static && 'pointer-events-none',
      _ctx.miniCard && 'mini-card',
    ]])
      }, [
        _createVNode(_component_a_card, {
          class: "overflow-hidden cursor-pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-click', _ctx.performer.id)))
        }, {
          cover: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_a_image, {
                preview: false,
                alt: _ctx.performer.performerProfile?.nickname,
                src: _ctx.performer.performerProfile?.avatar ?? '#',
                class: "performer-card__thumbnail",
                fallback: _ctx.fallbackSrc
              }, null, 8, ["alt", "src", "fallback"])
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.selected)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_AppIcon, {
                    name: "checkmarkBlue",
                    class: "absolute top-0 right-0 performer-card__selected-icon"
                  }),
                  _createVNode(_component_AppIcon, {
                    name: "trashGray",
                    class: "absolute top-0 right-0 performer-card__delete-icon",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('on-delete', _ctx.performer.id)), ["stop"]))
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_a_typography_title, {
                level: 5,
                class: "performer-card__title mb-0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.performer.performerProfile?.nickname), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_PerformerRating, { performer: _ctx.performer }, null, 8, ["performer"]),
              _createVNode(_component_PerformerBadgeList, {
                "performer-profile": _ctx.performer.performerProfile
              }, null, 8, ["performer-profile"]),
              (!_ctx.miniCard)
                ? (_openBlock(), _createBlock(_component_PerformerIndustries, {
                    key: 0,
                    performer: _ctx.performer
                  }, null, 8, ["performer"]))
                : _createCommentVNode("", true),
              (_ctx.performerStats.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.performerStats, (detail, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: detail.label
                      }, [
                        _createVNode(_component_IconLabel, {
                          text: detail.value
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_AppIcon, {
                              name: detail.iconName
                            }, null, 8, ["name"])
                          ]),
                          _: 2
                        }, 1032, ["text"]),
                        (index < _ctx.performerStats.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, " | "))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128)),
                    (_ctx.performer.performerProfile?.location)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_IconLabel, {
                            text: _ctx.performer.performerProfile?.location
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_AppIcon, {
                                name: "location",
                                class: "performer-card__location-icon"
                              })
                            ]),
                            _: 1
                          }, 8, ["text"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ], 2))
}